"use client";
import useLocation from "@/lib/hooks/useLocation";
import { eventProductList } from "@/lib/utils/Analytics/Functions";
import { PlpBannerStoryblok } from "@/types/storyblok-blok-types";
import { useEffect, useRef } from "react";
import { usePlp } from "../../hooks/usePlp";
import { type InitialPlpData } from "../../serverUtils/getInitialPlpData";
import PlpModals from "../PlpModals";
import PlpTopBar from "../PlpTopBar";
import ProductsSection from "../ProductsSection/ProductsSection";

type Props = {
  story: any;
  initialProductData: InitialPlpData["products"];
  currentCategory: string[];
  // categories: InitialPlpData["categories"];
  params?: { category: string[]; locale: string };
  bloks?: PlpBannerStoryblok[];
  initialFilterData?: InitialPlpData["initialFilterData"];
};

const ClientPlp = ({
  story,
  params,
  initialProductData,
  bloks,
  currentCategory,
  initialFilterData,
}: Props) => {
  const { ModalsProps, clearFilters, ProductsData } = usePlp({
    initialProductData,
    categories: currentCategory,
    initialFilterData,
    bloks: bloks,
  });
  const eventFired = useRef(false);
  const { currency } = useLocation();
  useEffect(() => {
    if (!eventProductList || !currency) {
      return;
    }
    if (!eventFired.current) {
      eventProductList({
        currency: currency || "",
        id: story?._uid || "",
        name: story?.centraCategory || "",
      });
      eventFired.current = true;
    }
  }, [currency]);

  return (
    <>
      <PlpTopBar
        productCount={ProductsData?.productCount}
        tags={ModalsProps.filterModalProps.tags ?? []}
        showFilters={story?.showFilters}
        clearFilters={clearFilters}
        showSort={story?.showSort}
        sortOrder={ModalsProps.SortOrderProps}
      />
      <div style={{ position: "relative" }}>
        <PlpModals
          filters={{
            ...ModalsProps.filterModalProps,
            params,
          }}
          productCount={ProductsData?.productCount}
        />
      </div>
      <ProductsSection
        data={ProductsData}
        id={story?._uid}
        name={story?.centraCategory}
      />
    </>
  );
};

export default ClientPlp;
