"use client";

import { Button, ProductCard } from "@/components/atoms";
import SkeletonPC from "@/components/atoms/ProductCard/SkeletonPC";
import ProductGrid from "@/components/molecules/ProductGrid";
import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";
import useDevice from "@/lib/hooks/useDevice";
import useIntersection from "@/lib/hooks/useIntersection";
import useScrollToTop from "@/lib/hooks/useScrollToTop";
import {
  convertCardToGTagItem,
  eventProductListSelect,
} from "@/lib/utils/Analytics/Functions";
import { PlpBannerStoryblok } from "@/types/storyblok-blok-types";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { useSearchParams } from "next/navigation";
import { Fragment } from "react";
import type { PLPContent, PlpProductsSectionProps } from "../../hooks/usePlp";
import styles from "./index.module.css";

const ProductsSection = ({
  data,
  id,
  name,
}: {
  data: PlpProductsSectionProps;
  id?: string;
  name?: string;
}) => {
  const t = useTranslations();
  const searchparams = useSearchParams();
  const scrollToTop = useScrollToTop();
  const { plpContent, fetchNextPage, hasNextPage, isFetching } = data;
  const callbackFunction = (
    entries: IntersectionObserverEntry[],
    _observer: IntersectionObserver,
  ) => {
    const [entry] = entries;
    if (entry.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  };
  const { Ref } = useIntersection(callbackFunction);
  const products = plpContent.reduce(
    (acc: PLPContent[number]["products"], curr) => {
      return [...acc, ...curr.products];
    },
    [],
  );

  return (
    <>
      <section
        style={{ display: "flex", flexDirection: "column", gap: "6rem" }}
      >
        <ProductGrid id="grid">
          {products?.map((product, index) => {
            return (
              <ProductCard
                product={product}
                key={`product-${product?.core?.slug}-${index}`}
                priority={index < 5}
                onClick={() => {
                  eventProductListSelect({
                    id: id,
                    name: name,
                    items: [
                      convertCardToGTagItem(
                        product,
                        product?.description?.price?.[0]?.priceAsNumber,
                      ),
                    ],
                  });
                }}
              />
            );
          })}

          {!!plpContent &&
            searchparams.size === 0 &&
            plpContent?.map((section, i) => (
              <Fragment key={i}>
                <ProductEditorial
                  section={section}
                  productLength={products?.length}
                />
              </Fragment>
            ))}
        </ProductGrid>
        {hasNextPage && (
          <>
            <div id="observer" ref={Ref}>
              <SkeletonPC />
            </div>
            <SkeletonPC /> <SkeletonPC /> <SkeletonPC />
          </>
        )}
      </section>

      {!hasNextPage && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "1.5rem",
            padding: "24px 0",
          }}
        >
          <Button outlined onClick={scrollToTop}>
            {t("productListingPage.backToTop")}
          </Button>
        </div>
      )}
    </>
  );
};

export default ProductsSection;

type ProductEditorialProps = {
  section: any;
  productLength: number;
};

const ProductEditorial = ({
  section,
  productLength,
}: ProductEditorialProps) => {
  const { desktop } = useDevice();
  if (!section || !productLength) return null;

  // If product count is less than 4, do not display any editorial blocks
  if (productLength < 4) return null;

  return (
    <>
      {section?.editorial.map(
        (editorialBlok: PlpBannerStoryblok, key: number) => (
          <div
            className={clsx(
              styles.editorial,
              styles?.[`${editorialBlok?.alignment}`],
            )}
            key={key}
            style={{
              gridRow: !!desktop
                ? editorialBlok?.row
                : editorialBlok?.rowMobile,
            }}
          >
            <StoryblokComponent blok={editorialBlok} />
          </div>
        ),
      )}
    </>
  );
};
